
import React from "react";
import { NavLink } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import "./comman.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
const Project = () => {

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <meta name="keywords" content="" />
                    <title>Residential Projects in Pune | 2 BHK | 3 BHK | 4 BHK |Shri Samarth builders</title>
                </Helmet>
            </HelmetProvider>


            <section>
                <div className='contact-bg'>
                    <div className='container p-2'>
                        <div className='wrap w-100 d-flex align-items-center '>
                            <div className='page-title-bar-inner d-flex flex-column align-items-center w-100 p-5 '>
                                <div className='page-header pt-3'>
                                    <h1 className='text-white p-2' style={{ fontFamily: "Marcellus SC", fontSize: "25px", }}>PROJECTS</h1>
                                </div>
                                <div className='breadcrumb  d-flex text-aline-center ' >
                                    <div className=' ' >
                                        <NavLink className="borA  " exact to="/" style={{ textDecoration: "none" }}> <li className="" style={{ fontSize: "16px", color: "#cabe9f", margin: "0px 5px", fontFamily: "Montserrat" }}>Home  &#x203A; Project </li> </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className='mt-5 pb-5'>
                <div className='container FontFamilyMontSerret'>
                    <div className='row d-flex justify-content-around align-items-center pt-5 '>
                        <div className='col-lg-6 '>
                            <div className='d-flex'>
                                <img src={require("../images/icon-12.png")} class="d-block mt-3 " style={{ width: '20px', height: '10px' }} alt="..." />
                                <p className='fw-bold ms-2 mt-2' style={{ fontFamily: "Marcellus SC,-apple-system,BlinkMacSystemFont,Segoe UI, Roboto,Helvetica Neue, Arial,sans-serif", fontSize: "24px", color: "#3f4448", }}>We are a professional and reputable company</p>
                            </div>
                            <p className='' style={{ textAlign: "justify", lineHeight: "25px", color: "#787878", fontSize: '12px' }}>We are in the field of Civil Engineering for Construction of Industrial & Commercial, Residential Projects. We are Committed for Quality, Economy & speed with latest technology.</p>
                            <p className='' style={{ textAlign: "justify", lineHeight: "25px", color: "#787878", fontSize: '12px' }}>Since our establishment in the year 2008, we Rohan Associates have been engaged in providing a wide range of civil construction based services. These meet the demands of various industrial, commercial and individual establishments. Our company is a building contractor and civil contractor for construction of industrial building as well as in areas like land development work, road work and many more</p>
                            <p className='' style={{ textAlign: "justify", lineHeight: "25px", color: "#787878", fontSize: '12px' }}>Under the able and efficient guidance of our mentor Mr.Sachin Khupse, we have emerged as one of the prominent service providers in construction industry. Our construction services are widely appreciated and recommended by our reputed clientele across the Pune city.</p>
                            <p className="" style={{ textAlign: "justify", lineHeight: "25px", color: "#787878", fontSize: '12px' }}>We have carried out our range of construction services have been utilized in the past by many reputed industries, educational institutes, commercial establishments and many others. Also providing building contractor and civil contractors in Pune.</p>
                            <p className="" style={{ textAlign: "justify", lineHeight: "25px", color: "#787878", fontSize: '12px' }}>Relying on our modern infrastructure, we can successfully undertake designing & prototype construction demands as per the exact specifications provide by the clients. Our in-house design unit as well as in depth process knowledge of experienced industry professionals also help us in delivering end solutions exactly as per the specifications provided by the clients.</p>
                        </div>
                        <div className='col-lg-5 '>
                            <img src={require("../images/Sonstruction Site.jpg")} class="d-block w-100" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className='back-1'>
                <div className='container mb-3 p-lg-5 FontFamilyMontSerret' >
                    <div className='row'>
                        <div className='col-lg-12 p-4'>
                            <h1 className='d-flex justify-content-center MarcellusFONT' style={{ fontSize: "28px", fontWeight: '600', color: "#3f4448" }}>FEATURED PROPERTIES</h1>
                        </div>
                    </div>
                    <div className='container p-3'>
                        <div className='row  ' >
                            <div className="col-md-4 p-2">
                                <div className='box border border-dark bg-light p-2'>
                                    <div className='box-text p-lg-4'>
                                        <h4 className='fw-bold' style={{ fontSize: "24px" }}>SUN CREST</h4>
                                        <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>Awarded Premium housing project of the year by ET NOW</p>
                                        <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                                            <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                                                <b className='p-3'>KNOW MORE</b>
                                            </button>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <img src={require("../images/Sun-Crest.jpg")} class="d-block w-100" alt="..." />
                                    </div>
                                    <div className='text-center pe-3' style={{ color: "#787878" }}>
                                        <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                                        <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN CREST</h4>
                                        <p>Dhayari, Sinhagad Road</p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-4  p-2">
                                <div className='box border  border-dark bg-light p-2'>
                                    <div className='box-text p-lg-4'>
                                        <h4 className='fw-bold' style={{ fontSize: "24px" }}>SUN VISTA</h4>
                                        <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>A unique layout for the best utilisation of space.</p>
                                        <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                                            <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                                                <b className='p-3'>KNOW MORE</b>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={require("../images/Sun-Vista.jpg")} class="d-block w-100" alt="..." />
                                    </div>
                                    <div className='text-center pe-3' style={{ color: "#787878" }}>
                                        <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                                        <h4 className='' style={{ fontSize: "24px", fontWeight: "bold" }}>SUN VISTA</h4>
                                        <p>Sun City Road</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4  p-2">
                                <div className='box border border-dark bg-light p-2'>
                                    <div className='box-text p-lg-4'>
                                        <h4 className='fw-bold' style={{ fontSize: "24px" }}>24 CARATS</h4>
                                        <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>Extraordinarily lavish homes with complete privacy.</p>
                                        <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                                            <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                                                <b className='p-3'>KNOW MORE</b>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={require("../images/24-carats-min.jpg")} class="d-block w-100" alt="..." />

                                    </div>
                                    <div className='text-center pe-3' style={{ color: "#787878" }}>
                                        <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                                        <h4 className='fw-bold' style={{ fontSize: "24px", }}>24 CARATS</h4>
                                        <p>Pradhikaran, Nigdi</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container mt-lg-3' >
                        <div className='row'>
                            <div className="col-md-4  p-2">
                                <div className='box border  border-dark bg-light p-2'>
                                    <div className='box-text p-lg-4'>
                                        <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN CITY</h4>
                                        <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>An iconic property rebuilt at Ambegaon.</p>
                                        <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                                            <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                                                <b className='p-3'>KNOW MORE</b>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={require("../images/sun-city-min.jpg")} class="d-block w-100" alt="..." />

                                    </div>
                                    <div className='text-center pe-3' style={{ color: "#787878" }}>
                                        <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                                        <h4 className='' style={{ fontSize: "24px", fontWeight: "bold" }}>SUN CITY</h4>
                                        <p>Shihgad Road </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4  p-2">
                                <div className='box border  border-dark bg-light p-2'>
                                    <div className='box-text p-lg-4'>
                                        <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN EXOTICA</h4>
                                        <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>Awarded residential property of the year by ET NOW.</p>
                                        <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                                            <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                                                <b className='p-3'>KNOW MORE</b>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={require("../images/exotica-min.jpg")} class="d-block w-100" alt="..." />

                                    </div>
                                    <div className='text-center pe-3' style={{ color: "#787878" }}>
                                        <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                                        <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN CREST</h4>
                                        <p>Yewalewadi, Kondhwa(Bk)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4  p-2">
                                <div className=' box border border-dark bg-light p-2'>
                                    <div className='box-text p-lg-4'>
                                        <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN HARMONY</h4>
                                        <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>A life of lush greens and modern conveniences.</p>
                                        <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                                            <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                                                <b className='p-3'>KNOW MORE</b>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={require("../images/harmony-small-thumb.jpg")} class="d-block w-100" alt="..." />
                                    </div>
                                    <div className='text-center pe-3' style={{ color: "#787878" }}>
                                        <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                                        <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN CREST</h4>
                                        <p>Near Toll Plaza, Moshi</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-4 d-flex justify-content-center' >
                        <NavLink className="mt-3" exact to="/project">  <button type="button" class="btn btn-secondary" style={{ backgroundColor: "#5B5B5B", padding: "11px", fontSize: "14px", borderRadius: "0px 0px 0px 0px", border: "1px solid #ab8c47" }} >
                            <b className='p-3' style={{ fontWeight: "400", letterSpacing: '2px' }} >VIEW  ALL  PROPERTIES</b>
                        </button></NavLink>
                    </div>
                </div>
            </section> */}

            <section className="back-1 pb-5">
                <div className="container FontFamilyMontSerret p-lg-5 ">
                    <div className='row'>
                        <div className='col-lg-12 p-4'>
                            <h1 className='d-flex justify-content-center MarcellusFONT' style={{ fontSize: "28px", fontWeight: '600', color: "#3f4448" }}>COMPLETED PROJECT </h1>
                        </div>
                    </div>
                    <div className='container '>
                        <div className='row  p-lg-3' >
                            <div className="col-md-4 p-2">
                                <div className='box border border-dark bg-light p-2'>
                                    <div className='box-text p-lg-4'>
                                        <h4 className='fw-bold' style={{ fontSize: "24px" }}>SUN CREST</h4>
                                        <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>Awarded Premium housing project of the year by ET NOW</p>
                                        <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                                            <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                                                <b className='p-3'>KNOW MORE</b>
                                            </button>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <img src={require("../images/Sun-Crest.jpg")} class="d-block w-100" alt="..." />
                                    </div>
                                    <div className='text-center pe-3' style={{ color: "#787878" }}>
                                        <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                                        <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN CREST</h4>
                                        <p>Dhayari, Sinhagad Road</p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-4  p-2">
                                <div className='box border  border-dark bg-light p-2'>
                                    <div className='box-text p-lg-4'>
                                        <h4 className='fw-bold' style={{ fontSize: "24px" }}>SUN VISTA</h4>
                                        <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>A unique layout for the best utilisation of space.</p>
                                        <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                                            <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                                                <b className='p-3'>KNOW MORE</b>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={require("../images/Sun-Vista.jpg")} class="d-block w-100" alt="..." />
                                    </div>
                                    <div className='text-center pe-3' style={{ color: "#787878" }}>
                                        <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                                        <h4 className='' style={{ fontSize: "24px", fontWeight: "bold" }}>SUN VISTA</h4>
                                        <p>Sun City Road</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4  p-2">
                                <div className='box border border-dark bg-light p-2'>
                                    <div className='box-text p-lg-4'>
                                        <h4 className='fw-bold' style={{ fontSize: "24px" }}>24 CARATS</h4>
                                        <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>Extraordinarily lavish homes with complete privacy.</p>
                                        <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                                            <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                                                <b className='p-3'>KNOW MORE</b>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={require("../images/24-carats-min.jpg")} class="d-block w-100" alt="..." />

                                    </div>
                                    <div className='text-center pe-3' style={{ color: "#787878" }}>
                                        <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                                        <h4 className='fw-bold' style={{ fontSize: "24px", }}>24 CARATS</h4>
                                        <p>Pradhikaran, Nigdi</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container mt-lg-3 ' >
                        <div className='row p-lg-3'>
                            <div className="col-md-4  p-2">
                                <div className='box border  border-dark bg-light p-2'>
                                    <div className='box-text p-lg-4'>
                                        <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN CITY</h4>
                                        <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>An iconic property rebuilt at Ambegaon.</p>
                                        <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                                            <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                                                <b className='p-3'>KNOW MORE</b>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={require("../images/sun-city-min.jpg")} class="d-block w-100" alt="..." />

                                    </div>
                                    <div className='text-center pe-3' style={{ color: "#787878" }}>
                                        <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                                        <h4 className='' style={{ fontSize: "24px", fontWeight: "bold" }}>SUN CITY</h4>
                                        <p>Shihgad Road </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4  p-2">
                                <div className='box border  border-dark bg-light p-2'>
                                    <div className='box-text p-lg-4'>
                                        <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN EXOTICA</h4>
                                        <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>Awarded residential property of the year by ET NOW.</p>
                                        <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                                            <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                                                <b className='p-3'>KNOW MORE</b>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={require("../images/exotica-min.jpg")} class="d-block w-100" alt="..." />

                                    </div>
                                    <div className='text-center pe-3' style={{ color: "#787878" }}>
                                        <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                                        <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN CREST</h4>
                                        <p>Yewalewadi, Kondhwa(Bk)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4  p-2">
                                <div className=' box border border-dark bg-light p-2'>
                                    <div className='box-text p-lg-4'>
                                        <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN HARMONY</h4>
                                        <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>A life of lush greens and modern conveniences.</p>
                                        <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                                            <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                                                <b className='p-3'>KNOW MORE</b>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={require("../images/harmony-small-thumb.jpg")} class="d-block w-100" alt="..." />
                                    </div>
                                    <div className='text-center pe-3' style={{ color: "#787878" }}>
                                        <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                                        <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN CREST</h4>
                                        <p>Near Toll Plaza, Moshi</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid p-0">
                    <div className="container p-lg-5 mt-4 ">
                        <div className="row g-4 p-lg-4">
                            <div className="col-lg-4 col-md-4 ">
                                <div className="p-lg-4 m-lg-1">
                                    <div className=" bg-image ">
                                        <img src={require("../images/project1.png")} class="bg-image1 w-100" alt="..." />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className="p-lg-4 m-lg-1 ">
                                    <div className=" bg-image">
                                        <img src={require("../images/project2.png")} class="bg-image1 w-100" alt="..." />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className="p-lg-4 m-lg-1 ">
                                    <div className=" bg-image">
                                        <img src={require("../images/project3.png")} class="bg-image1 w-100" alt="..." />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className="p-lg-4 m-lg-1 ">
                                    <div className=" bg-image">
                                        <img src={require("../images/project4.png")} class="bg-image1 w-100" alt="..." />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className="p-lg-4 m-lg-1 ">
                                    <div className=" bg-image">
                                        <img src={require("../images/project5.png")} class="bg-image1 w-100" alt="..." />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className="p-lg-4 m-lg-1 ">
                                    <div className=" bg-image">
                                        <img src={require("../images/project6.png")} class="bg-image1 w-100" alt="..." />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className="p-lg-4 m-lg-1 ">
                                    <div className=" bg-image">
                                        <img src={require("../images/project7.png")} class="bg-image1 w-100" alt="..." />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className="p-lg-4 m-lg-1 ">
                                    <div className=" bg-image">
                                        <img src={require("../images/project8.png")} class="bg-image1 w-100" alt="..." />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className="p-lg-4 m-lg-1 ">
                                    <div className=" bg-image">
                                        <img src={require("../images/project9.png")} class="bg-image1 w-100" alt="..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section>
                <div className="container">
                    <div className="row p-3">
                        <div className="col-md-3">
                            <div className="bg-image p-lg-3 m-1 ">
                                <img src={require("../images/home.png")} class=" bg-image1 w-100 shadow-lg  mb-5 bg-body rounded" alt="..." />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="bg-image p-lg-3 m-1">
                                <img src={require("../images/home-2.png")} class=" bg-image1 w-100 shadow-lg  mb-5 bg-body rounded" alt="..." />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className=" bg-image p-lg-3 m-1">
                                <img src={require("../images/home-3.png")} class=" bg-image1 w-100 shadow-lg  mb-5 bg-body rounded" alt="..." />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="bg-image p-lg-3 m-1">
                                <img src={require("../images/home-4.png")} class=" bg-image1 w-100 shadow-lg  mb-5 bg-body rounded" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="pb-5 mt-4" style={{ backgroundColor: '#D5DADF' }}>
                <div className="container p-3 FontFamilyMontSerret ">
                    <div className="text-center p-lg-5">
                        <p className=" fw-bold" style={{ fontFamily: "Marcellus SC,-apple-system,BlinkMacSystemFont,Segoe UI, Roboto,Helvetica Neue, Arial,sans-serif", fontSize: "30px", color: "#3f4448", }}>Construction Services</p>
                        <p className="mt-lg-4" style={{ textAlign: "center", lineHeight: "30px", color: "#787878", fontSize: '14px' }}>Backed by over 8 Years of experience in the field of Real Estate development Rohan Associates, has professionals that are highly trained in all aspects of the construction industry. From accumulation, from destruction to construction, we know what needs to be done and how to do it.
                            We provide clients a wide range of construction services for their varied civil construction related requirements. Meeting the specific demands of industrial, commercial or individual establishments, our service range includes:
                        </p>
                    </div>
                </div>

                <div className='container mt-2 mb-3  FontFamilyMontSerret'>
                    <div className='row g-4'>
                        <div className='col-lg-4 col-md-6'>
                            <div className=' h-100 shadow p-3  bg-body rounded ' style={{ borderRadius: "20px" }}>
                                <div className=" p-3 ">
                                    <img src={require("../images/icon.png")} class="rounded mx-auto d-block w-25" alt="..." />
                                </div>
                                <div className="mt-2">
                                    <p className="text-center fw-bold" style={{ color: "#54595f", fontSize: '18px' }}>Promoters, Builders, Developer </p>

                                    <p className='mt-lg-4' style={{ fontSize: "16px", color: "#777", textAlign: 'center', lineHeight: '25px' }}>Shree Samarth Group has built one of the quality & authentic group of buildings in PCMC.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6'>
                            <div className='h-100 shadow p-3  bg-body rounded ' style={{ borderRadius: "20px" }}>
                                <div className=" p-2 ">
                                    <img src={require("../images/icon4.png")} class="rounded mx-auto d-block w-25 " alt="..." />
                                </div>
                                <div className="mt-2">
                                    <p className="text-center fw-bold " style={{ color: "#54595f", fontSize: '18px' }}>Warehouse Construction </p>
                                    <p className='mt-lg-4' style={{ fontSize: "15px", color: "#777", textAlign: 'center', lineHeight: '25px' }}>We direct all our activities to serve the expectations of customers by providing them best quality products as per their satisfaction.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6'>
                            <div className='h-100 shadow p-3 bg-body rounded ' style={{ borderRadius: "20px" }}>
                                <div className=" p-2  ">
                                    <img src={require("../images/icon3.png")} class="rounded mx-auto d-block w-25" alt="..." />
                                </div>
                                <div className="mt-2">
                                    <p className="text-center fw-bold" style={{ color: "#54595f", fontSize: '18px' }}>Industrial Project</p>

                                    <p className='mt-lg-4' style={{ fontSize: "16px", color: "#777", textAlign: 'center', lineHeight: '25px' }}>We also do the Industrial Project for our Valuable Customer</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6'>
                            <div className='h-100 shadow p-3  bg-body rounded' style={{ borderRadius: "20px", backgroundColor: '#D5DADF' }}>
                                <div className=" p-2">
                                    <img src={require("../images/icon2.png")} class="rounded mx-auto d-block w-25" alt="..." />
                                </div>
                                <div className="mt-2">
                                    <p className="text-center fw-bold" style={{ color: "#54595f", fontSize: '18px' }}>Commercial Project</p>
                                    <p className='mt-lg-4' style={{ fontSize: "16px", color: "#777", textAlign: 'center', lineHeight: '25px' }}>Commercial, Industrial and Public Facilities Land Development</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6'>
                            <div className='h-100 p-3  shadow bg-body rounded ' style={{ borderRadius: "20px" }}>
                                <div className=" p-3 ">
                                    <img src={require("../images/icon1.png")} class="rounded mx-auto d-block w-25 mt-3" alt="..." />
                                </div>
                                <div className="mt-2">
                                    <p className="text-center fw-bold " style={{ color: "#54595f", fontSize: '18px' }}>Residential Projects</p>
                                    <p className='mt-lg-4' style={{ fontSize: "16px", color: "#777", textAlign: 'center', lineHeight: '25px' }}>Shri Samarth Group is group to a vibrant community of low-rise buildings that boast residential apartments.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6'>
                            <div className='h-100 shadow p-3  bg-body rounded' style={{ borderRadius: "20px", backgroundColor: '#D5DADF' }}>
                                <div className=" p-2">
                                    <img src={require("../images/icon5.png")} class="rounded mx-auto d-block w-25" alt="..." />
                                </div>
                                <div className="mt-2">
                                    <p className="text-center fw-bold" style={{ color: "#54595f", fontSize: '18px' }}>Land (Plot) development </p>

                                    <p className='mt-lg-4' style={{ fontSize: "16px", color: "#777", textAlign: 'center', lineHeight: '25px' }}>Benefit of the socie where we are oper ate success for your</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Project;