import React from "react";
import { Route, Routes } from "react-router-dom";
import Contact from "./Contact";
import Fabrictors from "./Fabrictors";
import Flyash from "./Flyash";
import Home from "./Home";
import Project from "./Project";
import SSG from "./SSG";
import Header from "./Header";
import Footer from "./Footer";
import Error from "./Error";


const Routing = ()=>{

    return(
        <>
        <header className="sticky-top">
            <Header/>
        </header>
        <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route exact path="/ssg-Philosophy"element={<SSG/>}/>
            <Route exact path="/fabrictors" element={<Fabrictors/>}/>
            <Route exact path="/flyash-bricks" element={<Flyash/>}/>
            <Route exact path="/Contact-us"element={<Contact/>}/>
            <Route exact path="/project" element={<Project/>}/>

            <Route exact path="*" element={<Error/>}/>
        </Routes>
        <footer>
            <Footer/>
        </footer>
        </>
    )
}
export default Routing;