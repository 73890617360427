import React from 'react';
import { NavLink } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import { Helmet, HelmetProvider } from 'react-helmet-async';
const Fabrictors = () => {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <meta name="keywords" content="" />
                    <title>Shri Samarth Fabricators</title>
                </Helmet>
            </HelmetProvider>
           

            <section>
                <div className='contact-bg'>
                    <div className='container p-2'>
                        <div className='wrap w-100 d-flex align-items-center '>
                            <div className='page-title-bar-inner d-flex flex-column align-items-center w-100 p-5 '>
                                <div className='page-header pt-3'>
                                    <h1 className='text-white p-2' style={{ fontFamily: "Marcellus SC,Segoe UI, Arial,sans-serif", fontSize: "25px", }}>FABRICTORS</h1>
                                </div>
                                <div className='breadcrumb  d-flex text-aline-center ' >
                                    <div className=' ' >
                                        <NavLink className="borA  " exact to="/" style={{ textDecoration: "none" }}> <li className="" style={{ fontSize: "16px", color: "#cabe9f", margin: "0px 5px", fontFamily: "Montserrat" }}>Home  &#x203A; Fabrictors </li> </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className='back-1 mt-5'>
                <div className='container'>
                    <div className='row d-flex justify-content-around align-items-center  pt-5 pb-5 '>
                        <div className='col-lg-6 '>
                            <div className='d-flex'>
                                <img src={require("../images/icon-12.png")} class="d-block mt-3 " style={{ width: '20px', height: '10px' }} alt="..." />
                                <p className='ps-3 fw-bold' style={{ fontFamily: "Marcellus SC,-apple-system,BlinkMacSystemFont,Segoe UI, Roboto,Helvetica Neue, Arial,sans-serif", fontSize: "25px", color: "#3f4448", }}>Shri Samarth Fabricators</p>
                            </div>
                            <p className='' style={{ textAlign: "justify",  lineHeight: "26px", color: "#787878",fontSize:"14px" }}>Shri Samarth FabricatorsShri Samarth Fabricators based in Pune Backed by over 12 years of experience in the field of fabrication work. Shri Samarth Fabricators,based on the original site, our head office and fabrication workshops are in prime location. All workshops are well equipped with facilities for machining, light fabrication projects, pipe spooling, plant and general maintenance, structural steel,plate fabrication, and coded welding.</p>
                            <p className='' style={{ textAlign: "justify",  lineHeight: "26px", color: "#787878",fontSize:"14px" }}>The Success of the business is in its highly flexible workforce with no demarcation between trades. A number of the staff has been employed with the company for many years and all trades are supported by a highly qualified, professional management team</p>
                            <p className='' style={{ textAlign: "justify",  lineHeight: "26px", color: "#787878",fontSize:"14px" }}>Our Quality Assurance and health and safety Policies. Shri Samarth Fabricators offers in house sheet metal. Welding, fabrication, machining and painting facilities and workshops that produce custom designed products and services including- Light & heavy fabrication, General engineering and machining, All classes of welding, including aluminum and stainless steel, sheet metal work, poly pipe welding.</p>
                            <p className='' style={{ textAlign: "justify",  lineHeight: "26px", color: "#787878",fontSize:"14px" }}>We provide an end to end production facility with direct access to stock, parts and equipment for the job at hand. On our workshop we complete jobs of all size and complexity. Our client’s needs are meticulously designed, drafted and specifications detailed.</p>
                            <p className='' style={{ textAlign: "justify",  lineHeight: "26px", color: "#787878",fontSize:"14px" }}>Our highly skilled and trained trade’s people and supervisors offer our clients a one stop shop ensuring the most effective outcome for the client and the company.</p>
                        </div>
                        <div className='col-lg-5 p-lg-4'>
                            <div className='pt-lg-5'>
                                <img src={require("../images/_Fabricators.jpg")} class="d-block w-100 mt-lg-5" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

           
        </>
    )
}
export default Fabrictors;