
import React from 'react';
import { NavLink } from 'react-router-dom';
import Footer from './Footer';
import "./Home.css";
import Header from './Header';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Home = () => {

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <title>Shri Samarth Group: Top Real Estate Developer and Property Builder in Pune. </title>
        </Helmet>
      </HelmetProvider>


      <section>
        <div className='container-fluid p-lg-0 ' >
          <div id="carouselExampleAutoplaying" class="carousel slide " data-bs-ride="carousel">
            <div class="carousel-inner FontFamilyMontSerret">
              <div class="carousel-item active">
                <section>
                  <div className="container-fluid p-lg-0 ">
                    <div className='position-relative' >
                      <img src={require("../images/Slider-1.jpg")} class="d-block w-100 home-silder" alt="..." />
                      <div className='position-absolute p-lg-5 p-3 ' style={{ backgroundColor: "rgba(7, 1, 1, 0.600)", top: "25%", right: "10%" }}>
                        <h4 className="text-center  text-white" style={{ fontSize: "30px", fontWeight: "800", letterSpacing: "3px" }} >SUN CITY</h4>
                        <h6 className="text-center fw-bold  text-white mb-3" style={{ letterSpacing: "4px", fontSize: "13px" }} >AMBEGAON</h6>
                        <p className='text-center text-white pt-2' style={{ fontSize: "15px", letterSpacing: "2px" }}>A PLACE WHERE<br /> THE ORDINARY IS MADE <br /> EXTRAORDINARY</p>
                        <div className='d-flex justify-content-center'>
                          <button className='text-white' style={{ backgroundColor: "#AB8C47", border: "1px solid #AB8C47", borderRadius: '50px', padding: "8px", fontSize: "13px", fontWeight: "800", }}>
                            <b className='p-3'>KNOW MORE</b>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="carousel-item ">
                <section>
                  <div className="container-fluid p-lg-0 ">
                    <div className='position-relative'>
                      <img src={require("../images/Slider-2.jpg")} class="d-block w-100 home-silder" alt="..." />
                      <div className='position-absolute p-lg-5 p-3 ' style={{ backgroundColor: "rgba(7, 1, 1, 0.600)", top: "25%", right: "10%" }}>
                        <h4 className="text-center  text-white" style={{ fontSize: "30px", fontWeight: "800", letterSpacing: "3px" }} >SUN CREST</h4>
                        <h6 className="text-center fw-bold  text-white mb-3" style={{ letterSpacing: "4px", fontSize: "13px" }} >DHAYARI</h6>
                        <p className='text-center text-white pt-2' style={{ fontSize: "15px", letterSpacing: "2px" }}>A PLACE WHERE<br /> THE ORDINARY IS MADE <br /> EXTRAORDINARY</p>
                        <div className='d-flex justify-content-center'>
                          <button className='text-white' style={{ backgroundColor: "#AB8C47", border: "1px solid #AB8C47", borderRadius: '50px', padding: "8px", fontSize: "13px", fontWeight: "800", }}>
                            <b className='p-3'>KNOW MORE</b>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="carousel-item ">
                <section>
                  <div className="container-fluid p-lg-0 ">
                    <div className='position-relative'>
                      <img src={require("../images/slider-3.jpg")} class="d-block w-100 home-silder" alt="..." />
                      <div className='position-absolute p-lg-5 p-3 ' style={{ backgroundColor: "rgba(7, 1, 1, 0.600)", top: "25%", right: "10%" }}>
                        <h4 className="text-center  text-white" style={{ fontSize: "30px", fontWeight: "800", letterSpacing: "3px" }} >SUN VISTA</h4>
                        <h6 className="text-center fw-bold  text-white mb-3" style={{ letterSpacing: "4px", fontSize: "13px" }} >SUN CITY ROAD</h6>
                        <p className='text-center text-white pt-2' style={{ fontSize: "15px", letterSpacing: "2px" }}>A PLACE WHERE<br /> THE ORDINARY IS MADE <br /> EXTRAORDINARY</p>
                        <div className='d-flex justify-content-center'>
                          <button className='text-white' style={{ backgroundColor: "#AB8C47", border: "1px solid #AB8C47", borderRadius: '50px', padding: "8px", fontSize: "13px", fontWeight: "800", }}>
                            <b className='p-3'>KNOW MORE</b>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </section>

      <section className='back-1'>
        <div className='container mb-3 p-lg-5 FontFamilyMontSerret' >
          <div className='row'>
            <div className='col-lg-12 p-4'>
              <h1 className='d-flex justify-content-center MarcellusFONT' style={{ fontSize: "28px", fontWeight: '600', color: "#3f4448" }}>FEATURED PROPERTIES</h1>
            </div>
          </div>
          <div className='container p-3'>
            <div className='row  ' >
              <div className="col-md-4 p-2">
                <div className='box border border-dark bg-light p-2'>
                  <div className='box-text p-lg-4'>
                    <h4 className='fw-bold' style={{ fontSize: "24px" }}>SUN CREST</h4>
                    <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>Awarded Premium housing project of the year by ET NOW</p>
                    <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                      <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                        <b className='p-3'>KNOW MORE</b>
                      </button>
                    </div>
                  </div>
                  <div className=''>
                    <img src={require("../images/Sun-Crest.jpg")} class="d-block w-100" alt="..." />
                  </div>
                  <div className='text-center pe-3' style={{ color: "#787878" }}>
                    <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                    <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN CREST</h4>
                    <p>Dhayari, Sinhagad Road</p>
                  </div>

                </div>
              </div>
              <div className="col-md-4  p-2">
                <div className='box border  border-dark bg-light p-2'>
                  <div className='box-text p-lg-4'>
                    <h4 className='fw-bold' style={{ fontSize: "24px" }}>SUN VISTA</h4>
                    <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>A unique layout for the best utilisation of space.</p>
                    <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                      <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                        <b className='p-3'>KNOW MORE</b>
                      </button>
                    </div>
                  </div>
                  <div>
                    <img src={require("../images/Sun-Vista.jpg")} class="d-block w-100" alt="..." />
                  </div>
                  <div className='text-center pe-3' style={{ color: "#787878" }}>
                    <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                    <h4 className='' style={{ fontSize: "24px", fontWeight: "bold" }}>SUN VISTA</h4>
                    <p>Sun City Road</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4  p-2">
                <div className='box border border-dark bg-light p-2'>
                  <div className='box-text p-lg-4'>
                    <h4 className='fw-bold' style={{ fontSize: "24px" }}>24 CARATS</h4>
                    <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>Extraordinarily lavish homes with complete privacy.</p>
                    <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                      <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                        <b className='p-3'>KNOW MORE</b>
                      </button>
                    </div>
                  </div>
                  <div>
                    <img src={require("../images/24-carats-min.jpg")} class="d-block w-100" alt="..." />

                  </div>
                  <div className='text-center pe-3' style={{ color: "#787878" }}>
                    <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                    <h4 className='fw-bold' style={{ fontSize: "24px", }}>24 CARATS</h4>
                    <p>Pradhikaran, Nigdi</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container mt-lg-3' >
            <div className='row'>
              <div className="col-md-4  p-2">
                <div className='box border  border-dark bg-light p-2'>
                  <div className='box-text p-lg-4'>
                    <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN CITY</h4>
                    <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>An iconic property rebuilt at Ambegaon.</p>
                    <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                      <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                        <b className='p-3'>KNOW MORE</b>
                      </button>
                    </div>
                  </div>
                  <div>
                    <img src={require("../images/sun-city-min.jpg")} class="d-block w-100" alt="..." />

                  </div>
                  <div className='text-center pe-3' style={{ color: "#787878" }}>
                    <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                    <h4 className='' style={{ fontSize: "24px", fontWeight: "bold" }}>SUN CITY</h4>
                    <p>Shihgad Road </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4  p-2">
                <div className='box border  border-dark bg-light p-2'>
                  <div className='box-text p-lg-4'>
                    <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN EXOTICA</h4>
                    <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>Awarded residential property of the year by ET NOW.</p>
                    <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                      <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                        <b className='p-3'>KNOW MORE</b>
                      </button>
                    </div>
                  </div>
                  <div>
                    <img src={require("../images/exotica-min.jpg")} class="d-block w-100" alt="..." />

                  </div>
                  <div className='text-center pe-3' style={{ color: "#787878" }}>
                    <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                    <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN CREST</h4>
                    <p>Yewalewadi, Kondhwa(Bk)</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4  p-2">
                <div className=' box border border-dark bg-light p-2'>
                  <div className='box-text p-lg-4'>
                    <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN HARMONY</h4>
                    <p className='fw-bold mt-lg-3' style={{ fontSize: "12px", letterSpacing: "1px" }}>A life of lush greens and modern conveniences.</p>
                    <div className='d-flex justify-content-start mt-lg-4 pt-3'>
                      <button className='' style={{ backgroundColor: "#fff", border: "1px solid #fff", color: "#AB8C47", padding: "12px", fontSize: "14px", fontWeight: "800" }}>
                        <b className='p-3'>KNOW MORE</b>
                      </button>
                    </div>
                  </div>
                  <div>
                    <img src={require("../images/harmony-small-thumb.jpg")} class="d-block w-100" alt="..." />
                  </div>
                  <div className='text-center pe-3' style={{ color: "#787878" }}>
                    <p className='mt-2'>2BHK PREMIUM APARMENT</p>
                    <h4 className='fw-bold' style={{ fontSize: "24px", }}>SUN CREST</h4>
                    <p>Near Toll Plaza, Moshi</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='p-4 d-flex justify-content-center' >
            <NavLink className="mt-3" exact to="/project">  <button type="button" class="btn btn-secondary" style={{ backgroundColor: "#5B5B5B", padding: "11px", fontSize: "14px", borderRadius: "0px 0px 0px 0px", border: "1px solid #ab8c47" }} >
              <b className='p-3' style={{ fontWeight: "400", letterSpacing: '2px' }} >VIEW  ALL  PROPERTIES</b>
            </button></NavLink>
          </div>
        </div>
      </section>

      <section className='mt-lg-5'>
        <div className='container p-3 FontFamilyMontSerret '>
          <div className='row d-flex justify-content-around align-items-center'>
            <div className='col-lg-5 mt-3' >
              <h2 className=' fw-bold' style={{ color: "#3f4448" }}>ABOUT US</h2>
              <h6 className='pe-lg-5 mt-3' style={{ fontSize: "12px", textAlign: "justify", letterSpacing: "0.25px", lineHeight: "26px", color: "#787878" }} >
                Shri Samarth Group, established in 1998, is a Real Estate firm from India providing residential spaces with luxurious and modern amenities that are designed to suit your every need. The group commenced with the project ‘Sun City’ on Sinhgad Road which has now become a landmark on the Pune skyline. Mittal Group has served thousands of happy and satisfied home owners.
              </h6>
              <button type="button" class="btn btn-link m-0 p-0 " style={{ color: "#787878", fontSize: "16px" }} >Read more</button>
              <div className='d-flex p-lg-3 pt-lg-3 mt-2 '>
                <div className='col-md-3'>
                  <img src={require("../images/img-1.png")} class="d-block w-75" alt="..." />
                </div>
                <div className='col-md-3'>
                  <img src={require("../images/img-2.png")} class="d-block w-75" alt="..." />
                </div>
                <div className='col-md-3'>
                  <img src={require("../images/img-3.png")} class="d-block w-75" alt="..." />
                </div>
                <div className='col-md-3'>
                  <img src={require("../images/img-4.png")} class="d-block w-75" alt="..." />
                </div>
              </div>

            </div>
            <div className='col-lg-6  '>
              <div className='mt-2'>
                <img src={require("../images/about-1.jpg")} class="d-block w-100" style={{ height: "20rem" }} alt="..." />
              </div>
            </div>
          </div>
        </div>

        <div className='container mt-lg-5 p-lg-4 p-4'>
          <div className='row d-flex justify-content-center  pt-lg-5 pb-lg-5'>
            <div className=' col-lg-3  line-bar' >
              <div className=' d-flex  ' style={{ fontFamily: "Roboto Slab", fontSize: "2.5rem" }}>
                <p className='' style={{ fontSize: '36px', fontFamily: "Marcellus SC, Sans-serif" }}>8</p>
                Million+</div>
              <p className='pb-lg-3' style={{ fontFamily: "Marcellus SC", color: "#54595f", fontWeight: "bold", fontSize: "21px" }}>Sq. ft. Area developed</p>
            </div>
            <div className=' line-bar col-lg-3  ps-lg-4 '>
              <div className='d-flex' style={{ fontFamily: "Roboto Slab", fontSize: "2.5rem" }}>
                <p className='' style={{ fontFamily: "Marcellus SC, Sans-serif", }}>  3.5</p>
                Million+</div>
              <p className='pb-lg-3 fw-bold' style={{ fontFamily: "Marcellus SC", color: "#54595f", fontSize: "21px" }}>Sq. ft. Area under development</p>
            </div>
            <div className=' line-bar col-lg-3  ps-lg-5'>
              <div className='d-flex' style={{ fontFamily: "Roboto Slab", fontSize: "2.5rem" }}>
                5000+</div>
              <p className=' pb-lg-3 fw-bold' style={{ fontFamily: "Marcellus SC", color: "#54595f", fontSize: "21px" }}>Happy Families</p>
            </div>
            <div className=' line-bar col-lg-3  ps-lg-5 '>
              <div className='d-flex ' style={{ fontFamily: "Roboto Slab", fontSize: "2.5rem" }}>
                <p className=' fw-bold' style={{ fontFamily: "Marcellus SC" }}></p>
                200+</div>
              <p className='pb-lg-3 fw-bold' style={{ fontFamily: "Marcellus SC", color: "#54595f", fontSize: "21px" }}>Proud Employees</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid p-0" style={{ backgroundColor: "#F8F8F8" }} >
          <div className="container ">
            <div id="carouselExampleIndicators" class="carousel slide  w-100" data-bs-ride="carousel">
              <div class="carousel-inner  pt-lg-5 " >
                <div class="carousel-item active p-3">
                  <section>
                    <div className="container-fluid pb-4">
                      <div className="row d-flex justify-content-around m-lg-5">
                        <div className="col-lg-5 ">
                          <img className="img-fluid m-lg-2 " src={require("../images/Sapphire-award.jpg")} alt="Software-1" />
                        </div>
                        <div className="col-lg-6  pt-lg-4 ">
                          <div className='mt-lg-5 mt-2'>
                            <h4 className=" home-web" style={{ fontFamily: "Marcellus SC" }}>SUN SAPPHIRE</h4>
                            <p className='mt-lg-3' style={{ fontSize: "16px", color: "#9ea0a6", textAlign: "left", lineHeight: "28px", fontFamily: "Montserrat" }}>Another Shri Samarth, Sun Sapphire by Shri Samarth Group begged the title of Premium Housing Project of the year at the prestigious Real Estate Awards by ET NOW for its high-end luxury flats with top-notch amenities for the modern Indian citizen.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div class="carousel-item p-3 ">
                  <section>
                    <div className="container-fluid pb-4">
                      <div className="row d-flex justify-content-around m-lg-5">
                        <div className="col-lg-5">
                          <img className=" img-fluid m-lg-2  " src={require("../images/Mittal-builders-award-1500x1125-1.jpg")} alt="home-s2" />
                        </div>
                        <div className="col-lg-6  pt-lg-4  ">
                          <div className='mt-lg-5'>
                            <h4 className=" home-web  " style={{ fontFamily: "Marcellus SC", textAlign: "left" }}>TIMES AWARD FOR THE MOST TRUSTED<br /> DEVELOPER IN PUNE</h4>
                            <p className="mt-lg-3" style={{ fontSize: "16px", color: "#9ea0a6", textAlign: "left", lineHeight: "28px", fontFamily: "Montserrat" }}>Keeping the legacy intact and striving to exceed expectations as we bag yet another award as The Most Trusted Developer of Pune by Times.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div class="carousel-item p-3">
                  <section>
                    <div className="container-fluid pb-4">
                      <div className="row d-flex justify-content-around m-lg-5">
                        <div className="col-lg-5">
                          <img className="img-fluid m-lg-2 " src={require("../images/Shree Samarth.jpg")} alt="home-s2" />
                        </div>
                        <div className="col-lg-6 pt-lg-4 ">
                          <div className='mt-lg-5'>
                            <h4 className=" home-web " style={{ fontFamily: "Arial sans-serif " }}>SUN EXOTICA</h4>
                            <p className="mt-lg-3" style={{ fontSize: "16px", color: "#9ea0a6", textAlign: "left", lineHeight: "28px", fontFamily: "Montserrat" }} >Sun Exotica by Shri Samarth Group was awarded the Residential property of the year at the prestigious Real Estate Awards by ET NOW for its excellence and craftsmanship in property design, form and function that makes it one of the best places to call home.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span class="carousel-control-prev-icon bg-dark me-lg-5" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span class="carousel-control-next-icon bg-dark ms-lg-5" aria-hidden="true"></span>
                <span class="visually-hidden " >Next</span>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container-fluid'>
          <div className='container p-lg-5 FontFamilyMontSerret'>

            <div className='row p-lg-3'>
              <div className='pt-5' style={{ fontFamily: "Marcellus SC,-apple-system,BlinkMacSystemFont,Segoe UI, Roboto,Helvetica Neue, Arial,sans-serif", fontSize: "25px", color: "#3f4448", fontWeight: "bold" }}>
                <p className=''>LATEST POST</p>
              </div>
              <div className='col-md-4'>
                <div className='p-lg-3 p-lg-3 mb-5 '>
                  <div>
                    <img src={require("../images/Latest Post-1.jpg")} class="d-block w-100" alt="..." />
                  </div>
                  <div>
                    <p className='fw-bold mt-2' style={{ color: "#54595f" }}>7 BENEFITS OF BUYING A REDEVELOPMENT FLAT IN KOTHRUD.
                      <p className='' style={{ color: "#787878", fontSize: "12px" }}>June 13, 2023</p>
                    </p>

                    <p className='' style={{ fontSize: "14px", color: "#777" }}>Pune, a city known for its rapid development and thriving real estate market, has witnessed a significant surge in redevelopment projects these, </p>
                    <p className='fw-bold' style={{ color: "#61ce70", fontSize: "12px", fontFamily: "Sans-serif" }}> Read More
                      <i class="bi bi-chevron-double-right"></i>
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='p-lg-3  p-lg-3 mb-5 '>
                  <div>
                    <img src={require("../images/Latest Post-2.jpg")} class="d-block w-100" alt="..." />
                  </div>
                  <div>
                    <p className='fw-bold mt-2' style={{ color: "#54595f" }}>WHAT TO LOOK FOR WHEN BUYING A HOME FOR THE FIRST TIME?
                      <p className='' style={{ color: "#787878", fontSize: "12px" }}> April 7, 2023</p>
                    </p>

                    <p className='' style={{ fontSize: "14px", color: "#777" }}>Buying a home for the first time can be an exciting yet overwhelming experience. It’s likely to be one of the biggest investments you’ll make,</p>
                    <p className='fw-bold' style={{ color: "#61ce70", fontSize: "12px", fontFamily: "Sans-serif" }}> Read More
                      <i class="bi bi-chevron-double-right w-25"></i>

                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='p-lg-3  p-lg-3 mb-5'>
                  <div>
                    <img src={require("../images/Latest Post-3.jpg")} class="d-block w-100" alt="..." />
                  </div>
                  <div>
                    <p className='fw-bold mt-2' style={{ color: "#54595f" }}>LUXURIOUS 2BHKS AT PUNE’S SUN CITY AMBEGAON
                      <p className='' style={{ color: "#787878", fontSize: "12px" }}>November 17, 2022</p>
                    </p>

                    <p className='' style={{ fontSize: "14px", color: "#777" }}>With so many people choosing to settle down in Pune and making it a base city for their families, various builders have established various properties </p>
                    <p className='fw-bold' style={{ color: "#61ce70", fontSize: "12px", fontFamily: "Sans-serif" }}> Read More
                      <i class="bi bi-chevron-double-right"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='' style={{ backgroundColor: "#F8F8F8" }}>
        <div className='container p-lg-5 pt-4 pb-4'>
          <div className='d-flex justify-content-center mb-3' style={{ fontFamily: "Marcellus SC,Segoe UI, Arial,sans-serif", fontSize: "24px", fontWeight: "500", color: "#3f4448" }}>
            <h3>TESTIMONIALS</h3>
          </div>
          <div id="carouselExampleIndicators1" class="carousel slide  " data-bs-ride="carousel">
            <div class="carousel-indicators ">
              <button type="button" data-bs-target="#carouselExampleIndicators1" style={{ border: "1px solid black", backgroundColor: "black", borderRadius: "100%", height: "15px", width: "15px" }} data-bs-slide-to="0 " class="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators1" style={{ border: "1px solid black", backgroundColor: "black", borderRadius: "100%", height: "15px", width: "15px" }} data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators1" style={{ border: "1px solid black", backgroundColor: "black", borderRadius: "100%", height: "15px", width: "15px" }} data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div class="carousel-inner p-lg-3 ">
              <div class="carousel-item active ">
                <section>
                  <div className="container pb-4">
                    <div className="row text-center">
                      <div className="col-lg-12">
                        <p className='' style={{ fontSize: "15px", color: "#787878", lineHeight: "26px" }}>After being on the lookout for my dream home after 15+ Projects, my search finally ended at Sun City. <br /> The sales team more than helped with my decision making to book my dream home situated at one of the best convenient locations in the city. <br /> Excited to be a part of the Mittal family.  <br /> Thank you 😊 </p>
                      </div>
                      <div className="col-lg-12  p-lg-4">
                        <strong className='' style={{ fontFamily: "Marcellus SC", letterSpacing: "2px", fontWeight: '600', color: "#AB8C47", fontSize: "24px" }} > - Pramil Naik </strong>
                        <p className=" home-web" style={{ fontFamily: "Marcellus SC", letterSpacing: "1px", fontWeight: "600", color: "#AB8C47", fontSize: "24px" }}>SUN SAPPHIRE</p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="carousel-item ">
                <section>
                  <div className="container pb-4">
                    <div className="row text-center">
                      <div className="col-lg-12">
                        <p className='' style={{ fontSize: "15px", color: "#787878", lineHeight: "26px" }}> I recently booked an apartment at Sun Exotica by Mittal Group. <br /> This project at Yewalewadi has a great layout with open spaces and good ventilation, along with amenities like good garden space, well designed club house with swimming pool, gym with actual good equipment, community hall, etc. <br /> Glad to be part of this society. </p>
                      </div>
                      <div className="col-lg-12  p-lg-4">
                        <strong className='' style={{ fontFamily: "Marcellus SC", letterSpacing: "2px", fontWeight: '600', color: "#AB8C47", fontSize: "24px" }} > - Krishnakant Gosavi </strong>
                        <p className=" home-web" style={{ fontFamily: "Marcellus SC",letterSpacing: "1px", fontWeight: "600", color: "#AB8C47", fontSize: "24px" }}>Sun Exotica</p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="carousel-item">
                <section>
                  <div className="container pb-4">
                    <div className="row text-center">
                      <div className="col-lg-12 ">
                        <p className='' style={{ fontSize: "15px", color: "#787878", lineHeight: "26px" }}> Investing in Mittal builder sun city Ambegaon has been a great life choice for me. I personally researched about the project and found that it is the right fit for families living in Pune. Their projects connect the whole city seamlessly. With great connectivity and modern amenities, it is the right choice to make. Was searching for my dream home for almost a year and finally got one </p>
                      </div>
                      <div className="col-lg-12  p-lg-4">
                      <strong className='' style={{ fontFamily: "Marcellus SC", letterSpacing: "2px", fontWeight: '600', color: "#AB8C47", fontSize: "24px" }} > - Arun Admane  </strong>
                        <p className="home-web" style={{ fontFamily: "Marcellus SC",letterSpacing: "1px", fontWeight: "600", color: "#AB8C47", fontSize: "24px" }}>Sun City Ambegaon</p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </section>


    </>
  )
}

export default Home;