import React from 'react';
import { NavLink } from 'react-router-dom';
import Footer from './Footer';
import Header from "./Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';
const Flyash = () => {


    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <meta name="keywords" content="" />
                    <title>Flyash Bricks Manufacturer Firm</title>
                </Helmet>
            </HelmetProvider>



            <section>
                <div className='contact-bg'>
                    <div className='container p-2'>
                        <div className='wrap w-100 d-flex align-items-center '>
                            <div className='page-title-bar-inner d-flex flex-column align-items-center w-100 p-5 '>
                                <div className='page-header pt-3'>
                                    <h1 className='text-white p-2' style={{ fontFamily: "Marcellus SC,Segoe UI, Arial,sans-serif", fontSize: "25px", }}>Flyash Bricks</h1>
                                </div>
                                <div className='breadcrumb  d-flex text-aline-center ' >
                                    <div className=' ' >
                                        <NavLink className="borA  " exact to="/" style={{ textDecoration: "none" }}> <li className="" style={{ fontSize: "16px", color: "#cabe9f", margin: "0px 5px", fontFamily: "Montserrat" }}>Home  &#x203A; Flyash </li> </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='back-1 mt-5'>
                <div className='container pb-5 FontFamilyMontSerret'>
                    <div className='row d-flex justify-content-around align-items-center pt-5 '>
                        <div className='col-lg-5 '>
                            <div className='d-flex'>
                                <img src={require("../images/icon-12.png")} class="d-block mt-3 " style={{ width: '20px', height: '10px' }} alt="..." />
                                <p className='ps-3 fw-bold' style={{ fontFamily: "Marcellus SC,-apple-system,BlinkMacSystemFont,Segoe UI, Roboto,Helvetica Neue, Arial,sans-serif", fontSize: "25px", color: "#3f4448", }}>Flyash Bricks Manufacturer Firm :</p>
                            </div>
                            <p className='' style={{ textAlign: "justify",  lineHeight: "27px", color: "#787878",fontSize:"13px" }}>Shri Samarth Udyog deals with manufacturing and supply of top notch quality of flyash bricks & other cement products. Our focus on producing excellent quality material and unmatched service make sure that you have a one stop solution to all your requirements related to flyash bricks & other cement products. We are a customer centric organization and all our innovation is built around providing the best industry standard products to our client base. Our state of the art manufacturing unit makes sure that we consistently deliver durable goods with high dimensional accuracy and robust design.</p>
                            
                        </div>
                        <div className='col-lg-6 p-lg-4'>
                            <div className=''>
                                <img src={require("../images/bricks11.png")} class="rounded mx-auto d-block w-100 " alt="..." />
                            </div>
                           
                            {/* <div className='m-2'>
                                <img src={require("../images/bricks13.jpg")} class="rounded mx-auto d-block w-100 " data-aos="flip-up" alt="..." />
                            </div> */}
                        </div>
                    </div>

                    <div className='row d-flex justify-content-around align-items-center pt-5 '>
                        <div className='col-lg-5 '>
                            <div className='d-flex'>
                                <img src={require("../images/icon-12.png")} class="d-block mt-3 " style={{ width: '20px', height: '10px' }} alt="..." />
                                <p className='ps-3 fw-bold' style={{ fontFamily: "Marcellus SC,-apple-system,BlinkMacSystemFont,Segoe UI, Roboto,Helvetica Neue, Arial,sans-serif", fontSize: "25px", color: "#3f4448", }}>Flyash Bricks Advantage :</p>
                            </div>
                            <p className='' style={{ textAlign: "justify", lineHeight: "27px", color: "#787878",fontSize:"13px" }}>Due to perfect size saving in cement mortar for making the walls and plastering by 20-40%. Strength of supplied lot is similar. Plaster of Paris, putty can be applied directly without a backing coat of plaster so save cost very must. Lower water penetration to clay bricks. High strength – can be used for load bearing walls. Eco friendly using flayash.This is the eco-friendly product to replace the conventional clay bricks to save environment with extra strength, uniform size pleasant aesthetic, less wastages & economy in cement & mortar.</p>
                            <p className='fw-bold'> 6’’ Fly ash bricks size (150mm x 230mm x 75mm)</p>
                            <p className='fw-bold'> 5’’ Fly ash bricks size (130mm x 230mm x 75mm)</p>
                            <p className='fw-bold'> 4’’ fly ash bricks size (110mm x 230mm x 75mm)</p>
                            <p className='' style={{ textAlign: "justify", lineHeight: "27px", color: "#787878",fontSize:"13px" }}>From the above comparison it is very much clear that flyash bricks are superior to clay Bricks and use flyash bricks for construction without any hesitation.</p>
                        </div>
                        <div className='col-lg-6 '>
                            <div className=''>
                                <img src={require("../images/bricks10.jpg")} class="rounded mx-auto d-block w-100 " alt="..." />
                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </>
    )
}
export default Flyash;