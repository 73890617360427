import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#404245", }}>
                    <div className="container  FontFamilyMontSerret">
                        <div className="row  p-lg-5 pt-5 pb-5">
                            <div className="col-lg-4 p-lg-4 " >
                                <p className="" style={{ color: "#AB8C47", fontSize: "25px", fontFamily: "Marcellus SC" }}>Quick Links</p>
                                <div className="text-white" style={{ lineHeight: "13px", fontSize: "14px", fontWeight: "400" }}>
                                    <NavLink exact to="/" style={{ textDecoration: "none", color: "white" }}><p>Home</p></NavLink>
                                    <NavLink exact to="/ssg-Philosophy" style={{ textDecoration: "none", color: "white" }}><p>SSG Philosophy</p></NavLink>
                                    <NavLink exact to="/fabrictors" style={{ textDecoration: "none", color: "white" }}><p>Fabrictors</p></NavLink>
                                    <NavLink exact to="/flyash-bricks" style={{ textDecoration: "none", color: "white" }}><p>Flyash Bricks</p></NavLink>
                                    <NavLink exact to="/project" style={{ textDecoration: "none", color: "white" }}><p>Projects</p></NavLink>
                                    <NavLink exact to="/Contact-us" style={{ textDecoration: "none", color: "white" }}><p>Contact Us</p></NavLink>
                                </div>
                            </div>
                            <div className="col-lg-4 p-lg-4">
                                <p className="" style={{ color: "#AB8C47", fontSize: "25px" , fontFamily: "Marcellus SC"}}>Completed Projects</p>
                                <div className="w-75 text-white" style={{ borderBottom: "1px solid", lineHeight: "13px", fontSize: "14px", fontWeight: "400", letterSpacing: "0px" }}>
                                    <NavLink exact to="/project" style={{ textDecoration: "none", color: "white" }}><p>Sun Crest</p></NavLink>
                                    <NavLink exact to="/project" style={{ textDecoration: "none", color: "white" }}><p>Sun Vista</p></NavLink>
                                    <NavLink exact to="/project" style={{ textDecoration: "none", color: "white" }}><p>24 Carast</p></NavLink>
                                    <NavLink exact to="/project" style={{ textDecoration: "none", color: "white" }}><p>Sun City</p></NavLink>
                                    <NavLink exact to="/project" style={{ textDecoration: "none", color: "white" }}><p>Sun Exotica</p></NavLink>
                                    <NavLink exact to="/project" style={{ textDecoration: "none", color: "white" }}><p>Sun Harmony</p></NavLink>
                                </div>
                                <div className="pe-5 mt-3 p-lg-1">
                                    <img src={require("../images/logo.png")} class="d-block w-50" alt="logo.png" />
                                </div>
                            </div>
                            <div className="col-lg-4 p-lg-4">
                                <div>
                                    <p className="" style={{ color: "#AB8C47", fontSize: "25px", fontFamily: "Marcellus SC"}}>Contact Info</p>
                                    <div className="text-white" style={{ lineHeight: "30px", fontSize: "14px", fontWeight: "400", letterSpacing: "0px" }}>
                                        <p>Shri Heights, shop No.4,5,6 CTS.345, Dehu-Alandi Road,
                                            Chikhali, PCMC, Pune –411062,India
                                            <br /><a className="p-0" style={{ textDecoration: "none", color: "white", }} href="tel:+91 9527468898">Phone : +91 9850854746</a>
                                            <br /><a className="p-0" style={{ textDecoration: "none", color: "white", }} href="">Email : info@shrisamarthgroup.com  </a>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="mb-0" style={{ color: "#AB8C47", fontSize: "25px", fontFamily: "Marcellus SC" }}>Get Connectd With Us</p>
                                        <div className="d-flex mt-lg-3">
                                            <div className="m-1 p-1 bg-light rounded "><i class="bi bi-facebook p-lg-1"></i></div>
                                            <div className="m-1 p-1 bg-light rounded"><i class="bi bi-linkedin p-lg-1"></i></div>
                                            <div className="m-1 p-1 bg-light rounded"><i class="bi bi-instagram p-lg-1"></i></div>
                                            <div className="m-1 p-1 bg-light rounded"><i class="bi bi-youtube p-lg-1"></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="row d-flex justify-content-between p-3 pb-0">
                        <div className="col-md-6 ">
                            <p className="mb-0 " style={{  color: "#7a7a7a", fontSize: "13px" }}>Copyright © 2023 SHRI SAMARTH GROUP</p>
                        </div>
                        <div className="col-md-6">
                            <p className="mb-0 text-lg-end" style={{  color: "#7a7a7a", fontSize: "13px" }}>. Copyright All Right Reserved.</p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default Footer;