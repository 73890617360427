import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import "./Contact.css";
import Footer from './Footer';
import Header from './Header';
import { Helmet, HelmetProvider } from 'react-helmet-async';
const Contact = () => {

    const form = useRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [errors, setErrors] = useState({});
    const options = [
        { value: '', text: 'Select Project of intrest' },
        { value: 'Sun City Ambegoan', text: 'Sun City Ambegoan' },
        { value: 'Shri Heights', text: 'Shri Heights' },
        { value: 'Shri Residency', text: 'Shri Residency' },
        { value: 'Sun Exotica', text: 'Sun Exotica' },
        { value: 'Sun Crest', text: 'Sun Crest' },
        { value: 'Sun Enclave', text: 'Sun Enclave' },
        { value: 'Sun Vista', text: 'Sun Vista' },
        { value: '24 Carats', text: '24 Carats' }
    ];
    const [selected, setSelected] = useState(options[0].value);
    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validform();

        if (Object.keys(errors).length === 0) {

            errors.name = "";
            errors.number = "";
            errors.email = "";
            errors.selected = "";




            var url = "https://wa.me/9527468898?text="
                + "NAME:" + name + "NUMBER:" + number + "EMAIL:" + email + "SELECTED:" + selected;

            window.open(url, '_blank').focus();
        }






        else {
            setErrors(errors);
        }


        setName("");
        setEmail("");
        setNumber("");
        setSelected("");

    }




    const validform = () => {
        const errors = {};
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Name is Required";
        }
        else if (name.length < 4) {
            errors.name = "Name Required At Least Four Characters";
        }

        if (!number) {
            errors.number = "Number Is Requred";
        }
        else if (number.length !== 10) {
            errors.number = "Number Must have 10 Digit";
        }

        if (!email) {
            errors.email = "Email is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {
                console.log("gh")

            }

            else {
                console.log("aa")
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }
        if (!selected) {
            errors.selected = "city must be required"
        }
        return errors;
    }



    return (

        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <meta name="keywords" content="" />
                    <title>Contact Us for Your Next Perfect Home! | Shri Samarth builders</title>
                </Helmet>
            </HelmetProvider>


            <section>
                <div className='contact-bg '>
                    <div className='container p-2 '>
                        <div className='wrap w-100 d-flex align-items-center '>
                            <div className='page-title-bar-inner d-flex flex-column align-items-center w-100 p-5 '>
                                <div className='page-header pt-3'>
                                    <h1 className='text-white p-2' style={{ fontFamily: "Marcellus SC", fontSize: "25px", }}>CONTACT US</h1>
                                </div>
                                <div className='breadcrumb  d-flex text-aline-center ' >
                                    <div className=' ' >
                                        <NavLink className="borA  " exact to="/" style={{ textDecoration: "none" }}> <li className="" style={{ fontSize: "16px", color: "#cabe9f", margin: "0px 5px", fontFamily: "Montserrat" }}>Home  &#x203A;  Contact Us </li> </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row g-4 " >
                            <div className="col-lg-6  p-lg-5 " >
                                <div className='mt-2 FontFamilyMontSerret'>
                                    <p className=" ms-lg-4  fw-bold" style={{ fontSize: "23px" }} >CONTACT FORM</p>
                                    <form ref={form} onSubmit={loginHandle} class="needs-validation">
                                        <div className="row ms-lg-4 ps-lg-2 " >

                                            <div className="col-lg-12 mt-2">
                                                <input type="text" name="name" id="fname" className="form-control w-100" style={{ borderRadius: "0px 0px 0px 0px", borderColor: "#ab8c47", height: "44px" }} placeholder="Name*" onChange={e => setName(e.target.value)} value={name} />
                                                {errors.name && <div className='text-danger'>{errors.name}</div>}

                                            </div>
                                            <div className="col-lg-12 mt-4">

                                                <input type="email" name="email" id="email" className="form-control w-100" style={{ borderRadius: "0px 0px 0px 0px", borderColor: "#ab8c47", height: "44px" }} placeholder="Email*"
                                                    onChange={e => setEmail(e.target.value)}
                                                    value={email} />
                                                {errors.email && <div className='text-danger'>{errors.email}</div>}
                                            </div>
                                            <div className="col-lg-12 mt-4 text-dark">

                                                <input type="text" name="number" id="number" maxlength="10" className="form-control w-100" style={{ borderRadius: "0px 0px 0px 0px", borderColor: "#ab8c47", height: "44px", }} placeholder="Number*"
                                                    onChange={e => setNumber(e.target.value)}
                                                    value={number} />
                                                {errors.number && <div className='text-danger'>{errors.number}</div>}
                                            </div>

                                            <div className="col-lg-12 mt-4" >
                                                <select value={selected} onChange={e => setSelected(e.target.value)} style={{ height: '55px', borderRadius: "3px 3px 3px 3px", border: "1px solid #ab8c47", color: "#7A7A7A", width: "100%" }}>
                                                    {options.map(option => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.text}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.selected && <div className='text-danger'>{errors.selected}</div>}
                                            </div>
                                            <div className='mt-4'>
                                                <input type="submit" value="SUBMIT" className="btn btn-secondary p-2 w-25" style={{ borderRadius: "0px 0px 0px 0px", borderColor: "#ab8c47", height: "44px", backgroundColor: "#54595F" }} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 p-lg-5">
                                <div className='mt-3'>
                                    <p className="mb-1 fw-bold FontFamilyMontSerret" style={{ fontSize: "24px" }} >CONTACT INFO </p>
                                    <div className='mt-4 ' style={{ color: "#7A7A7A", fontSize: "16px", lineHeight: "15px", fontFamily: 'Roboto, Sans-serif' }}>
                                        <p className='FontFamilyMontSerret'>Shri Heights, shop No.4,5,6 CTS.345, Dehu - Alandi Road,</p>
                                        <p className='FontFamilyMontSerret'> Chikhali, PCMC, Pune - 411062,India</p>
                                        <p className='FontFamilyMontSerret'>Phone : +91 9850854746</p>
                                        <p>Email : <span className='text-dark' >info@shrisamarthgroup.com</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-0 mb-4 p-0 mt-4 ">
                    <div className='m-0'>
                        <iframe className='w-100 m-0' title="gmap" style={{ height: "350px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d120947.38911395648!2d73.74486197908695!3d18.68167234820881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b7ef2065b92d%3A0x852e5002dc61cd46!2sSHRI%20SAMARTH%20GROUP!5e0!3m2!1sen!2sin!4v1686655350768!5m2!1sen!2sin">
                        </iframe>
                    </div>
                </div>
            </section>


        </>
    )
}
export default Contact;