import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import "./comman.css";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CountUp from 'react-countup';
import ScrollTrigger from "react-scroll-trigger"

const SSG = () => {

    const [counterOn, setCounterOn] = useState(false);


    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <meta name="keywords" content="" />
                    <title>About Shri Samarth Group-A Real Estate Property Builder in Pune &amp; Mumbai </title>
                </Helmet>
            </HelmetProvider>




            <section>
                <div className='contact-bg FontFamilyMontSerret'>
                    <div className='container p-2'>
                        <div className='wrap w-100 d-flex align-items-center '>
                            <div className='page-title-bar-inner d-flex flex-column align-items-center w-100 p-5 '>
                                <div className='page-header pt-3'>
                                    <h1 className='text-white p-2' style={{ fontFamily: "Marcellus SC,Segoe UI, Arial,sans-serif", fontSize: "25px", }}>ABOUT</h1>
                                </div>
                                <div className='breadcrumb  d-flex text-aline-center ' >
                                    <div className=' ' >
                                        <NavLink className="  " exact to="/" style={{ textDecoration: "none" }}> <li className="" style={{ fontSize: "16px", color: "#cabe9f", margin: "0px 5px", fontFamily: "Montserrat" }}>Home  &#x203A;  About </li> </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='back-1 mt-lg-5 pb-3'>
                <div className='container p-lg-5 FontFamilyMontSerret'>
                    <div className='row p-lg-5 d-flex justify-content-around pt-5 '>
                        <div className='col-lg-5 col-md-6 '>
                            <div className='d-flex'>
                                <img src={require("../images/icon-12.png")} class="d-block mt-3 " style={{ width: '20px', height: '10px' }} alt="..." />
                                <p className='ps-3 fw-bold' style={{ fontFamily: "Marcellus SC,-apple-system,BlinkMacSystemFont,Segoe UI, Roboto,Helvetica Neue, Arial,sans-serif", fontSize: "25px", color: "#3f4448", }}>SHRI SAMARTH GROUP PHILOSOPHY</p>
                            </div>
                            <p className='' style={{ textAlign: "justify",  lineHeight: "28px", fontSize: "13px", color: "#787878" }}><b> Transparency:</b> The foundation of brand Shri Samarth Group is based on the core principle of transparency, which drives all our conduct. We don’t believe in holding back from our clients and customers and equally ensure that there is no compromise on the quality of our products.</p>
                            <p className='' style={{ textAlign: "justify", lineHeight: "28px", fontSize: "13px", color: "#787878" }}><b> Value Creation :</b> Any product built under the Shri Samarth Group brand is a landmark you will cherish for life. We ensure there is a consensus between the quality and value you get for the price you pay to buy a Shri Samarth Group product. We assure you an asset for your investment with us.</p>
                            <p className='' style={{ textAlign: "justify",  lineHeight: "28px", fontSize: "13px", color: "#787878" }}><b> Innovation:</b> At Shri Samarth Group, our constant endeavour remains to innovate better ways of working together. We value customer feedback, which is incorporated in designing our future projects.</p>
                        </div>
                        <div className='col-lg-6 col-md-6 p-0'>
                            <img src={require("../images/About-Us-Image1.jpg")} class="d-block w-100" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid p-lg-5  mb-2' style={{ backgroundImage: `url(${require("../images/Conter.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center' }}>
                    <div className="container FontFamilyMontSerret p-5" style={{  color: "white", fontWeight: 'bolder' }} >
                        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                            <div className="row d-flex justify-content-lg-center align-items-lg-center ">

                                <div className="col-lg-3 col-md-6 d-flex justify-content-center align-items-center">
                                    <div>
                                        <div style={{ fontSize: "48px", fontFamily: "serif" }}> {counterOn && <CountUp start={0} end={8000000} duration={2} delay={0}> </CountUp>} + </div>
                                        <p className='FontFamilyMontSerret mt-lg-2' style={{ fontSize: "15px" }}>SQ. FT. AREA DEVELOPED</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 d-flex justify-content-center align-items-center">
                                    <div>
                                        <div style={{ fontSize: "48px", fontFamily: "serif" }}> {counterOn && <CountUp start={0} end={3500000} duration={2} delay={0}> </CountUp>} + </div>
                                        <p className='FontFamilyMontSerret mt-lg-2' style={{ fontSize: "15px" }}>SQ. FT. AREA UNDER DEVELOPMENT</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6  d-flex justify-content-center align-items-center">
                                    <div >
                                        <div style={{ fontSize: "48px", fontFamily: "serif" }}> {counterOn && <CountUp start={0} end={5000} duration={2} delay={0}> </CountUp>} + </div>
                                        <p className='FontFamilyMontSerret mt-lg-2' style={{ fontSize: "15px" }}>HAPPY FAMILIES </p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 d-flex justify-content-center align-items-center">
                                    <div>
                                        <div style={{ fontSize: "48px", fontFamily: "serif" }}> {counterOn && <CountUp start={0} end={200} duration={2} delay={0}> </CountUp>} + </div>
                                        <p className='FontFamilyMontSerret mt-lg-2' style={{ fontSize: "15px" }}>PROUD EMPLOYEES</p>
                                    </div>
                                </div>

                            </div>
                        </ScrollTrigger>
                    </div>
                </div>

            </section>

            <section>
                <div className='container-fluid mt-5 mb-5'>
                    <div className="container FontFamilyMontSerret p-lg-5">
                        <div className='row d-flex justify-content-around align-items-center p-lg-1 '>
                            <div className='col-lg-5 col-md-6 '>
                                <div className=''   >
                                    <img src={require("../images/philosophy.png")} class="d-block w-100" alt="..." />
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-6'>
                                <div className=''>
                                    <div className='d-flex' >
                                        <img src={require("../images/icon-12.png")} class="d-block mt-4 " style={{ width: '20px', height: '10px' }} alt="..." />
                                        <p className='p-lg-1 ps-lg-3 ms-1 fw-bold mt-2' style={{ fontFamily: "Roboto,sans-serif", color: "#3f4448", fontSize: '30px' }}> OUR PHILOSOPHY</p>
                                    </div>
                                    <p className='pe-lg-5 ' style={{ fontSize: "13px", textAlign: "justify", letterSpacing: "0.25px", lineHeight: "27px", color: "#787878", fontWeight: "400", fontFamily: "Roboto,sans-serif", }} >
                                        Our work philosophy that gives priority to everlasting emotional bonds over material gains,translates into ever smiling faces! We constantly strive to satisfy ever changing needs of our customers which are at par with the latest market trends of Real Estate. The result of which we see in our more than 1000 satisfied customers! This is our investment for the future!
                                    </p>
                                </div>
                                <div className='container'>
                                    <div className='d-flex p-lg-3 pt-lg-3 ps-lg-5' >
                                        <div className='col-md-3'>
                                            <img src={require("../images/img-1.png")} class="d-block w-75" alt="..." />
                                        </div>
                                        <div className='col-md-3'>
                                            <img src={require("../images/img-2.png")} class="d-block w-75" alt="..." />
                                        </div>
                                        <div className='col-md-3'>
                                            <img src={require("../images/img-3.png")} class="d-block w-75" alt="..." />
                                        </div>
                                        <div className='col-md-3'>
                                            <img src={require("../images/img-4.png")} class="d-block w-75" alt="..." />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='pb-lg-4 pt-3' style={{ backgroundColor: '#5B5B5B' }}>
                <div className='container p-2 p-lg-5 FontFamilyMontSerret'>
                    <div className='row d-flex justify-content-around align-items-center pt-lg-5 mt-3'>
                        <div className='col-lg-5 col-md-6'>
                            <div className='text-light'  >
                                <div className='d-flex'>
                                    <img src={require("../images/icon-12.png")} class="d-block mt-4 " style={{ width: '20px', height: '10px' }} alt="..." />
                                    
                                    <p className='ps-lg-3 fw-bold ms-1' style={{ fontSize: '30px' }}>Mr. Sachin Khupse</p>
                                </div>
                                <p className='pe-lg-5 ' style={{ fontSize: "12px", textAlign: "justify",  lineHeight: "26px", fontWeight: "400" }}>Mr. Sachin Khupse is the Chairman of Shri Samarth Group .one of the leading privately held company in pune . An expert entrepreneur with over decades of experience in the real estate industry,</p>
                                <p className='pe-lg-5' style={{ fontSize: "12px", textAlign: "justify", lineHeight: "27px", fontWeight: "400" }}> Mr. Khupse plays a key role in shaping up the growth model for Shri Samarth Group . His wisdom and relationship has given Shri Samarth Group a strong foundation and his effective groundbreaking expertises are being leveraged in policy creation , process, administration and business development .</p>
                                <p className='pe-lg-5' style={{ fontSize: "12px", textAlign: "justify",  lineHeight: "27px", fontWeight: "400" }}> Mr. Khupse leads the company’s initiative to extend its presence in to strategic markets and its development plan.</p>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-lg-2'>

                                    </div>
                                    <div className='col-lg-8 ps-4' >
                                        <img src={require("../images/sachin-khupse-1.png")} class="d-block w-100" title='Mr. Sachin Khupse' style={{ borderRadius: '100%', border: "2px solid #AB8C47 " }} alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className='text-center p-3'>
                                <p className='text-white ps-lg-5' style={{ fontSize: '20px', fontWeight: "400" }}>Mr. SACHIN KHUPSE <br /> CHAIRMAN</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='mb-lg-5'>
                <div className='container p-2 p-lg-5 FontFamilyMontSerret'>
                    <div className='row d-flex justify-content-around align-items-center pt-lg-5 mt-3'>
                        <div className='col-lg-5 col-md-6'>
                            <div className='container'>
                                <div className='row '>
                                    <div className='col-lg-1'>
                                    </div>
                                    <div className='col-lg-8' >
                                        <img src={require("../images/jitendra.png")} class="d-block w-100 " title='Mr. Jitendra Khupse' style={{ borderRadius: '100%', border: "2px solid #AB8C47 " }} alt="..." />
                                    </div>
                                    <div className='col-lg-2'></div>
                                </div>
                            </div>
                            <div className='text-center pe-lg-3 pt-3'  >
                                <p className='text-dark pe-lg-5 text-center' style={{ fontSize: '21px', fontWeight: "400" }}>Mr. JITENDRA KHUPSE</p>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6'>
                            <div className='text-dark '>
                                <div className='d-flex' >
                                    <img src={require("../images/icon-12.png")} class="d-block mt-4 " style={{ width: '20px', height: '10px' }} alt="..." />
                                    <p className='ps-lg-3 ms-1 mt-2' style={{ fontWeight: "bold", fontSize: '30px' }}>Mr. Jitendra Khupse</p>
                                </div>
                                <p className='pe-lg-4' style={{ fontSize: "14px", textAlign: "justify",  lineHeight: "27px", fontWeight: "400", color: "#7A7A7A" }}>Mr. Jitendra Khupse is a Fabrication & Civil Work Excellent Knowledge person with more than 15 years of experience in building construction and Fabrication Work is associated with creating landmark projects in and around Pune. He is the Editor-in-Chief for the Fabrication book “A to Z of Practical Building Construction , Industrial Fabrication and its Management”.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='pb-lg-4' style={{ backgroundColor: '#5B5B5B' }}>
                <div className='container p-2 p-lg-5 FontFamilyMontSerret'>
                    <div className='row d-flex justify-content-around align-items-center pt-lg-5 mt-3'>
                        <div className='col-lg-5 col-md-6'>
                            <div className='text-light'  >
                                <div className='d-flex'>
                                    <img src={require("../images/icon-12.png")} class="d-block mt-4 " style={{ width: '20px', height: '10px' }} alt="..." />
                                    <p className='ps-lg-3 fw-bold ms-1 mt-2' style={{ fontSize: '30px' }}>Mr. NEERAJ Samarth</p>
                                </div>
                                <p className='pe-lg-5 ' style={{ fontSize: "12px", textAlign: "justify", lineHeight: "27px", fontWeight: "400" }}>‘When you run behind excellence, success follows’- and this stands true when it comes to Mr.Neeraj Samarth, director of Samarth Group.</p>
                                <p className='pe-lg-5' style={{ fontSize: "12px", textAlign: "justify", lineHeight: "27px", fontWeight: "400" }}> A Civil engineer from Pune University with an M.Sc degree from the University of Westminster, London, Neeraj Samarth has contributed a great deal to the construction business. Subsequently, he got the ball rolling in the family-run dairy business – Mr. Milk –in 2018 and ramped up the brand and its identity since its very inception.A perfect amalgam of having the right experience and bringing in innovation, he never ceases to make each day productive, which further burgeons Samarth Builders and the rest of the brands within Samarth Group.</p>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-lg-2'>

                                    </div>
                                    <div className='col-lg-8 ps-4' >
                                        <img src={require("../images/Niraj.png")} class="d-block w-100" title='Mr. Sachin Khupse' style={{ borderRadius: '100%', border: "2px solid #AB8C47 " }} alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className='text-center p-3'>
                                <p className='text-white ps-lg-5' style={{ fontSize: '20px', fontWeight: "400" }}>MR. NEERAJ Samarth <br /> DIRECTOR</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='mb-lg-5'>
                <div className='container p-2 p-lg-5 FontFamilyMontSerret'>
                    <div className='row d-flex justify-content-around align-items-center pt-lg-5 mt-3'>
                        <div className='col-lg-5 col-md-6'>
                            <div className='container'>
                                <div className='row '>
                                    <div className='col-lg-1'>
                                    </div>
                                    <div className='col-lg-8' >
                                        <img src={require("../images/yash.png")} class="d-block w-100 " title='Mr. Jitendra Khupse' style={{ borderRadius: '100%', border: "2px solid #AB8C47 " }} alt="..." />
                                    </div>
                                    <div className='col-lg-2'></div>
                                </div>
                            </div>
                            <div className='text-center pe-lg-3 pt-3'  >
                                <p className='text-dark pe-lg-5 text-center' style={{ fontSize: '21px', fontWeight: "400" }}>MR. YASH SAMARTH <br />DIRECTOR</p>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6'>
                            <div className='text-dark '>
                                <div className='d-flex' >
                                    <img src={require("../images/icon-12.png")} class="d-block mt-4 " style={{ width: '20px', height: '10px' }} alt="..." />
                                    <p className='ps-lg-3 ms-1 mt-2' style={{ fontWeight: "bold", fontSize: '30px' }}>MR. YASH SAMARTH </p>
                                </div>
                                <p className='pe-lg-4' style={{ fontSize: "12px", textAlign: "justify",  lineHeight: "27px", fontWeight: "400", color: "#7A7A7A" }}>Young & dynamic director of Mittal Group has laid foundation of this career by stepping into construction business. After completing his graduation in International Business from Pune University, he moved to London to pursue his masters in International Business and trade. Yash leads by building teams, encouraging accurate conversations and placing others first. He essentially postulates in spending time around the things that of immense importance, setting priorities, measuring outcomes, and rewarding them. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container p-lg-5 pt-5 FontFamilyMontSerret'>
                    <div className='ms-lg-5 d-flex'>
                        <img src={require("../images/icon-12.png")} class="d-block mt-2 " style={{ width: '20px', height: '15px' }} alt="..." />
                        <h3 className='MarcellusFONT ms-lg-3 ms-2 mb-4' style={{ fontWeight: "800", color: "#3f4448", fontSize: "26px" }} > OUR GROUP OF COMPANIES </h3>
                    </div>
                    <div className='row pb-3 p-lg-5 g-0'>

                        <div className='col-lg-4 col-md-6 '>
                            <div className=' h-100 p-4 text-white ' style={{ backgroundColor: "#F5F5F5" }} >
                                <img src={require("../images/Mr_Milk.png")} class="d-block w-75 ms-4 " title='Mr. Jitendra Khupse' alt="..." />
                                <div>
                                    <p className='text-center mt-3' style={{ color: "#7A7A7A", fontSize: "12.4px", lineHeight: "25px" }}>Mr.Milk An A2 dairy brand is a family-owned and operated business with the objective to deliver farm-fresh Desi Cow Milk that is produced by desi, hump-backed cows at our 85-acre farm in Wadgaon, Maval.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 '>
                            <div className='h-100 p-4 text-white ' style={{ backgroundColor: "#fff" }} >
                                <img src={require("../images/Learning-Curve.png")} class="d-block w-75 ms-4" title='Mr. Jitendra Khupse' alt="..." />
                                <div>
                                    <p className='text-center mt-3' style={{ color: "#7A7A7A", fontSize: "12.4px", lineHeight: "25px" }}>The Learning Curve in Ambegaon is a franchise of one of the top preschools in India and is dedicated to enhancing the experience of education for little ones.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 '>
                            <div className=' h-100 p-4 text-white ' style={{ backgroundColor: "#F5F5F5" }} >
                                <img src={require("../images/Goenka.png")} class="d-block w-75 ms-4 " title='Mr. Jitendra Khupse' alt="..." />
                                <div>
                                    <p className='text-center mt-3' style={{ color: "#7A7A7A", fontSize: "12.4px", lineHeight: "25px" }}> GD Goenka International School is an international boarding school located in Sonipat near Delhi(Maharastra) . The school is spread across 16 Acres promoting sports and other activities that help students find a balance between academics and sports.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 '>
                            <div className='h-100 p-4 text-white ' style={{ backgroundColor: "#fff" }} >
                                <img src={require("../images/Mgpp.png")} class="d-block w-75 ms-4" title='Mr. Jitendra Khupse' alt="..." />
                                <div>
                                    <p className='text-center mt-3' style={{ color: "#7A7A7A", fontSize: "12.4px", lineHeight: "25px" }}>Mittal Global Pack Pro (MGPP) manufactures high-quality, durable, and biodegradable paper cups for the Indian market, as well as for multiple clients around the world.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 '>
                            <div className=' h-100 p-4 text-white ' style={{ backgroundColor: "#F5F5F5" }} >
                                <img src={require("../images/Woodpecker.png")} class="d-block w-75 ms-lg-4" title='Mr. Jitendra Khupse' alt="..." />
                                <div>
                                    <p className='text-center mt-3' style={{ color: "#7A7A7A", fontSize: "12.4px", lineHeight: "25px" }}> Woodpecker is our furniture wing in Ambegaon, Pune focused on the manufacturing of designer modular furniture like wardrobes, beds, kitchens etc in plywood.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 '>
                            <div className='h-100 p-4 text-white ' style={{ backgroundColor: "#fff" }} >
                                <img src={require("../images/Greensutra.png")} class="d-block w-75 ms-4" title='Mr. Jitendra Khupse' alt="..." />
                                <div>
                                    <p className='text-center mt-3' style={{ color: "#7A7A7A", fontSize: "12.4px", lineHeight: "25px" }}> Greensutra farms, incepted in 2021 by Mittal Group is a staycation venture, wherein varieties of premium and luxurious private villas of picturesque locations, along with highest security and hygiene standards are made available to family and friends for a memorable staycation. Currently group has started with Lonavala, and plans to expand in other parts of the country.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 '>
                            <div className=' h-100 p-4 text-white ' style={{ backgroundColor: "#F5F5F5" }} >
                                <img src={require("../images/Helios.png")} class="d-block w-75 ms-4" title='Mr. Jitendra Khupse' alt="..." />
                                <div>
                                    <p className='text-center mt-3' style={{ color: "#7A7A7A", fontSize: "12.4px", lineHeight: "25px" }}> Helios International, located at Sun City Road is a proposed CBSE board school with holistic and hands-on knowledge like no other school in the country.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )

}

export default SSG;