import React from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";

const Header = () => {
  return (
    <>

      <section>
        <div className='container-fluid position-sticky-top p-0' style={{ backgroundColor: "#5B5B5B", }}   >
          <nav className="navbar p-3 navbar-expand-lg  " >
            <div className=''>
              <img className='' style={{ height: "4rem" }} src={require("../images/logo.png")} alt="logo.png" />
            </div>
            <button className="navbar-toggler text-white  " type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <i class="bi bi-pause"></i>
            </button>
            <div className="collapse navbar-collapse  " id="navbarNavDropdown">
              <div className='d-flex justify-content-lg-around  pt-3 w-100  '>
                <ul className="navbar-nav  d-flex justify-content-lg-end align-items-lg-center responsive-navbarr FontFamilyMontSerret " >
                  <div className="nav-item active ps-lg-1 pe-lg-4">
                    <NavLink className="text-decoration-none" to="/"> <li className="nav-item text-white " >Home</li> </NavLink>
                  </div>

                  <div className="nav-item ps-lg-1 pe-lg-4">
                    <NavLink className="text-decoration-none" to="/ssg-Philosophy" > <li className="nav-item text-white  " >SSG Philosophy</li> </NavLink>
                  </div>

                  <div className="nav-item ps-lg-1 pe-lg-4">
                    <NavLink className="text-decoration-none" to="/fabrictors" > <li className="nav-item text-white  " >Fabrictors</li> </NavLink>
                  </div>

                  <div className="nav-item ps-lg-1 pe-lg-4">
                    <NavLink className="text-decoration-none" to="/flyash-bricks" > <li className="nav-item text-white " >Flyash Bricks</li> </NavLink>
                  </div>

                  <div className="nav-item ps-lg-1 pe-lg-4">
                    <NavLink className="text-decoration-none" to="/project" > <li className="nav-item text-white " >Projects</li> </NavLink>
                  </div>

                  <div className="nav-item ps-lg-1 pe-lg-4">
                    <NavLink className="text-decoration-none" to="/Contact-us" > <li className="nav-item text-white " >Contact Us</li> </NavLink>
                  </div>

                    <div className="button-responsive" >
                      <a className="text-white " style={{ textDecoration: "none", letterSpacing: "2px", fontWeight: "600" }} href="tel:+91 9527468898">ENQUIRE NOW</a>
                  </div>

                </ul>
              </div>
            </div>
          </nav>
        </div>
      </section>


    </>
  )
}

export default Header;