import React, { useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import Routing from "./Components/Routing";
import ScrollToTop from "./ScrollToTop";
import "./Components/Home.css";
const App = () => {

    useEffect(() => {
        let mybutton = document.getElementById("btn-back-to-top");
        window.onscroll = function () {
            scrollFunction();
        };
        function scrollFunction() {
            if (
                document.body.scrollTop > 20 ||
                document.documentElement.scrollTop > 20
            ) {
                mybutton.style.display = "block";
            } else {
                mybutton.style.display = "none";
            }
        }
        mybutton.addEventListener("click", backToTop);
        function backToTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }, [])

    useEffect(() => {
        var circle = document.querySelector(".circle");
        var circle2 = document.querySelector(".circle2");
    
        document.addEventListener("mousemove", function (e) {
          circle.style.cssText = circle2.style.cssText = "left : " + e.clientX + "px; top:" + e.clientY + "px; ";
    
        })
      })
    

    return (
        <>
             <div>
                <button
                    type="button"
                    class="btn btn-danger btn-floating btn-lg"
                    id="btn-back-to-top">
                    <i class="bi bi-chevron-up "></i>
                </button>
            </div>
            
            <section>
                <div className='circle'></div>
                <div className='circle2'></div>
            </section>

            <BrowserRouter>

                <Routing />,
                <ScrollToTop />
            </BrowserRouter>

        </>

    )
}
export default App;
